<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          variant="primary"
          v-if="isPermittedForThisModule"
          @click="openCreateModal()"
        >
          Upload Flyers
        </b-button>
      </template>
    </header-slot>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="`/${route}/bank-of-flyers/preactive`"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >PRE ACTIVE</b-nav-item
      >
      <b-nav-item
        :to="`/${route}/bank-of-flyers/active`"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >ACTIVE</b-nav-item
      >
      <b-nav-item
        :to="`/${route}/bank-of-flyers/inactive`"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >INACTIVE</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <modal-create-and-edit
        v-if="openModalCreate"
        :item="emptyItem"
        :operationType="operationType"
        no-close-on-esc="false"
        no-close-on-backdrop="false"
        @close="closeCreateModal"
        @update="updateTable"
      />
      <router-view :key="$route.name"></router-view>
    </b-card>
  </div>
</template>

<script>
import ModalCreateAndEdit from "@/views/commons/components/bank-of-flyers/bank-of-flyers-module/modals/modalCreateAndEdit/ModalCreateAndEdit.vue"
import { mapActions } from "vuex"
export default {
  components: {
    ModalCreateAndEdit,
  },

  data() {
    return {
      openModalCreate: false,
      permittedModules: [12, 27], // Modules permitted for using create, update and upload modal-options
      operationType: 2, // 2: Create
      emptyItem: {},
    }
  },

  methods: {
    ...mapActions({
      A_UPDATE_TABLE: "ParagonBankOfFlyers/A_UPDATE_TABLE",
    }),

    updateTable() {
      this.A_UPDATE_TABLE(true)
      setTimeout(() => {
        this.A_UPDATE_TABLE(false)
      }, 1000)
    },

    openCreateModal() {
      this.openModalCreate = true
    },

    closeCreateModal() {
      this.openModalCreate = false
    },
  },

  computed: {
    route() {
      return this.$route.meta.route
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },

    isPermittedForThisModule() {
      return this.permittedModules.includes(this.moduleId)
    },
  },
}
</script>

<style scoped></style>
